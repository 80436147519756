import React, { useEffect, useState } from 'react'
import { Avatar, Box, Chip, Divider } from '@material-ui/core'
import { LiveroomParticipant } from '../../Views/MainView/AuthenticatedView/RoomView/Room/LiveRoom/hooks/useLiveroomParticipants'
import palette from '../../Common/theme/colors/palette'
import './PeopleListContainer.scss'
import styled from 'styled-components'
import { PeopleRounded } from '@material-ui/icons'
import PeopleListMenu from './PeopleListItem/PeopleListMenu/PeopleListMenu'
import { ParticipantType } from '../../Common/janus/clients/liveroom/LiveroomClient'
import { isStreamParticipant } from '../../Common/utils/streamUtils'

interface PeopleListContainerProps {
  currentProfile: {
    id: string,
    displayName: string,
    canPublish: boolean,
    type: null;
  }
  participants: LiveroomParticipant[],
  kickPermissionInfo,
  participantsManager,
  mutePermissionInfo,
}

interface PeopleRow {
  id: string;
  displayName: string;
  type: ParticipantType | null;
}

const StyledAvatar = styled(Avatar)`
  width: 30px;
  height: 30px;
  margin-right: 6px;
  padding-top: 3px;
  color: white;
  background: #151515;
  font-size: 18px;
`

const HeaderBox = styled(Box)`
  padding: 8px;
  color: #151515;
`

const HeaderChip = styled(Chip)`
  padding: 0 8px;
  font-size: 16px;
  background: #151515;
`

const PeopleBox = styled(Box)`
  line-height: 30px;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DisplayNameBox = styled(Box)`
  display: flex;
  justifyContent: flex-start;
  padding: 0.25rem 0rem 0.25rem 0rem;
`

const PeopleListContainer: React.FC<PeopleListContainerProps> = ({
  currentProfile,
  participants,
  kickPermissionInfo,
  participantsManager,
  mutePermissionInfo
}) => {

  const [viewers, setViewers] = useState<PeopleRow[]>([])
  const [publishers, setPublishers] = useState<PeopleRow[]>([])

  useEffect(() => {
    if (participants) {
      const viewers = participants.filter((participant) => !participant.publishing) as PeopleRow[]
      const publishers = participants.filter((participant) => participant.publishing) as PeopleRow[]
      if (currentProfile.canPublish) {
        publishers.push(currentProfile as PeopleRow)
      } else {
        viewers.push(currentProfile as PeopleRow)
      }

      setViewers(viewers)
      setPublishers(publishers)
    }
  }, [participants, currentProfile])

  const drawPeopleRow = (people: PeopleRow, canPublish: boolean) => {  
    const isParticipantStreamOnly = isStreamParticipant(people.type) || people.type === ParticipantType.AppleTV

    return (
      <PeopleBox key={people.id}>
        <DisplayNameBox>
          <StyledAvatar>
            {people.displayName.substring(0, 1)}
          </StyledAvatar>
          {people.displayName}
        </DisplayNameBox>
        <Box>
          {!isParticipantStreamOnly && currentProfile.id !== people.id && (kickPermissionInfo.isAvailable && kickPermissionInfo.isAllowed || mutePermissionInfo.isAvailable && mutePermissionInfo.isAllowed) && (
            <PeopleListMenu 
              name={people.displayName} 
              participantsManager={participantsManager}
              personId={people.id}
              canPublish={canPublish}
              kickPermissionInfo={kickPermissionInfo}
              mutePermissionInfo={mutePermissionInfo}
            />
          )}
        </Box>
      </PeopleBox>
    )
  }

  return (
    <Box bgcolor={palette.background.primary} width="100%" height="100%">
      <HeaderBox>
        <HeaderChip
          icon={<PeopleRounded />}
          label={publishers.length + viewers.length}
        />
      </HeaderBox>
      <Box className="people-list-container-content">
        <Box className="people-list-category">
          ACTIVE ({publishers.length})
        </Box>
        <Box padding={'8px'} style={{ overflowY: 'scroll' }}>
          {publishers.map((publisher) => drawPeopleRow(publisher, true))}
        </Box>
        <Divider />
        <Box className="people-list-category" marginTop={'16px'}>
          VIEWERS ({viewers.length})
        </Box>
        <Box padding={'8px'} marginBottom={'8px'} style={{ overflowY: 'scroll' }}>
          {viewers.map((viewer) => drawPeopleRow(viewer, false))}
        </Box>
      </Box>
    </Box>
  )
}

export default PeopleListContainer;