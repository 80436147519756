import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ParticipantType } from '../Common/janus/clients/liveroom/LiveroomClient'
import { StreamState } from '../Common/media/stream'
import { LiveroomParticipant } from '../Views/MainView/AuthenticatedView/RoomView/Room/LiveRoom/hooks/useLiveroomParticipants'

export const MIN_VOL = 0
export const MAX_VOL = 1

interface AudioMixerContextProps {
  masterVolume: number
  streamVolume: number
  isStreamVolumeEnabled: boolean
  setMasterVolume: (volume: number) => void
  setStreamVolume: (volume: number) => void
  participantsVolume: Record<string, number> | null
  getParticipantVolume: (participantId: string) => number
  setParticipantVolume: (participantId: string, participantVolume: number) => void
}

const audioMixerContext = createContext<AudioMixerContextProps>(null!)

interface AudioMixerProviderProps {
  children: JSX.Element
  participants: LiveroomParticipant[]
  isLiveroomAudioEnabled: boolean
}

export const AudioMixerProvider = ({
  children,
  participants,
  isLiveroomAudioEnabled
}: AudioMixerProviderProps): JSX.Element => {
  const [masterVolume, setMasterVolume] = useState(MAX_VOL)
  const [ebsParticipant, setEbsParticipant] = useState<LiveroomParticipant | null>(null)
  const [participantsVolume, setParticipantsVolume] = useState<Record<string, number> | null>(null)

  useEffect(() => {
    const participant = participants.find((participant) => participant.type === ParticipantType.Ebs)
    setEbsParticipant(participant || null)
  }, [participants])

  // Set volume of new participant that joined the room to MAX_VOL
  useEffect(() => {
    const participantsIds = participants.map((participant) => participant.id)

    setParticipantsVolume((prevState) => {
      const volumes = {}

      for (const id of participantsIds) {
        volumes[id] = prevState?.[id] || MAX_VOL
      }

      return volumes
    })
  }, [participants])

  const setMasterVolumeHandler = (volume: number) => {
    setMasterVolume(volume)
  }

  const getParticipantVolume = useCallback(
    (participantId: string) => {
      if (!participantsVolume) return 0
      return participantsVolume[participantId]
    },
    [participantsVolume]
  )

  const changeParticipantAudioTrackStatus = useCallback(
    (participantId: string, isAudioEnabled: boolean) => {
      const participant = participants.find((participant) => participant.id === participantId)

      if (participant?.stream?.state === StreamState.available) {
        participant.stream.current.getAudioTracks().forEach((track) => {
          track.enabled = isLiveroomAudioEnabled && isAudioEnabled
        })
      }
    },
    [isLiveroomAudioEnabled, participants]
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const changeAllParticipantsAudioTrackStatus = useCallback(
    (isAudioEnabled: boolean) => {
      participants.forEach((participant) =>
        changeParticipantAudioTrackStatus(participant.id, isAudioEnabled)
      )
    },
    [changeParticipantAudioTrackStatus, participants]
  )

  const setParticipantVolume = useCallback(
    (participantId: string, participantVolume: number) => {
      setParticipantsVolume((participants) => ({
        ...participants,
        [participantId]: participantVolume
      }))

      changeParticipantAudioTrackStatus(participantId, participantVolume > 0)
    },
    [changeParticipantAudioTrackStatus]
  )

  const streamVolume = useMemo(() => {
    if (ebsParticipant?.id) {
      return participantsVolume?.[ebsParticipant.id] || MAX_VOL
    }

    return MIN_VOL
  }, [ebsParticipant, participantsVolume])

  const setStreamVolumeHandler = useCallback(
    (volume: number) => {
      ebsParticipant?.id && setParticipantVolume(ebsParticipant.id, volume)
    },
    [ebsParticipant, setParticipantVolume]
  )

  const value = {
    masterVolume,
    streamVolume,
    participantsVolume,
    setParticipantVolume,
    getParticipantVolume,
    isStreamVolumeEnabled: !!ebsParticipant,
    setStreamVolume: setStreamVolumeHandler,
    setMasterVolume: setMasterVolumeHandler
  }

  return <audioMixerContext.Provider value={value}>{children}</audioMixerContext.Provider>
}

export const useAudioMixer = () => {
  return useContext(audioMixerContext)
}
