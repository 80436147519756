import React, { useCallback, useLayoutEffect, useState } from 'react'
import { LiveroomParticipant } from '../LiveRoom/hooks/useLiveroomParticipants'
import { LiveroomPublisher } from '../LiveRoom/hooks/useLiveroomPublisher'
import { LiveroomClient } from '../../../../../../Common/janus/clients/liveroom/LiveroomClient'
import {
  DATA_CHANNEL_PLAYBACK_MESSAGE_TYPE,
  PlaybackStatus,
} from '../LiveRoom/hooks/useStreamPlaybackControl'
import StreamPlayBarController from './StreamPlayBarController/StreamPlayBarController'
import { DamClient } from '../../../../../../Common/dam/DamClient'
import { DamStream } from '../LiveRoom/hooks/DamStream'

interface StreamPlaybackProps {
  publisher: LiveroomPublisher
  selectedStream: LiveroomParticipant | undefined
  liveroomClient: LiveroomClient | null
  damClient: DamClient | null
  isStreamer: boolean
  canPublish: boolean
}

export interface StreamInfo {
  playbackStatus: Partial<PlaybackStatus>
  frameRate: number
}

const StreamPlayBar: React.FC<StreamPlaybackProps> = ({
  publisher,
  selectedStream,
  liveroomClient,
  damClient,
  isStreamer,
  canPublish,
}) => {
  const [streamsInfo, setStreamsInfo] = useState<{ [streamId: string]: Partial<StreamInfo> }>({})

  /**
   * Handle a message from the liveroom signaling server about a participant's playback status.
   * If the message is a playback status message, it will update the participant's playbackStatus property.
   * @param event - The event containing the JSON message from the liveroom server.
   */
  const handlePlaybackStatusMessage = useCallback(
    (event: MessageEvent) => {
      const message = JSON.parse(event.data)
      const messageType = message?.type
      if (messageType !== DATA_CHANNEL_PLAYBACK_MESSAGE_TYPE || !message.status) return

      const streamId = message.streamId
      setStreamsInfo((streamStatus) => ({
        ...streamStatus,
        [streamId]: {
          ...streamStatus[streamId],
          playbackStatus: message.status,
        },
      }))
    },
    [setStreamsInfo]
  )

  const handleVideoMetadataMessage = useCallback(
    (event: MessageEvent) => {
      const message = JSON.parse(event.data)
      const messageType = message?.type

      const frameRate = message?.message?.sourceData?.frameRate

      if (messageType !== 'video-metadata-message' || !frameRate) return

      const streamId = message?.message?.sourceData?.sourceName
      setStreamsInfo((streamStatus) => ({
        ...streamStatus,
        [streamId]: {
          ...streamStatus[streamId],
          frameRate: message.message.sourceData.frameRate,
        },
      }))
    },
    [setStreamsInfo]
  )

  useLayoutEffect(() => {
    if (!liveroomClient) return
    /*
     * We need to listen to participant joining events here instead of in the useLiveroomParticipants hook
     * to avoid re-render other components.
     **/
    liveroomClient.onParticipantJoining().subscribe((participant) => {
      participant.onDataChannelMessage().subscribe((event) => {
        handleVideoMetadataMessage(event)
        handlePlaybackStatusMessage(event)
      })
    })
  }, [liveroomClient, handlePlaybackStatusMessage, handleVideoMetadataMessage])

  useLayoutEffect(() => {
    const damStreamVideoRef = (selectedStream?.stream as DamStream)?.videoReferences?.[1]?.current
    const streamId = selectedStream?.id

    if (!damStreamVideoRef || !streamId) return

    damStreamVideoRef.addEventListener('loadedmetadata', (e) => {
      if (!(e.target as HTMLVideoElement).duration) return

      setStreamsInfo((streamStatus) => ({
        ...streamStatus,
        [streamId]: {
          ...streamStatus[streamId],
          playbackStatus: {
            ...(streamStatus[streamId]?.playbackStatus || {}),
            duration: (e.target as HTMLVideoElement).duration * 1000,
          },
        },
      }))
    })
  }, [(selectedStream?.stream as DamStream)?.videoReferences?.[1]?.current])

  useLayoutEffect(() => {
    if (!damClient) return

    damClient.onStreamProgress().subscribe((serverStream) => {
      if (!serverStream) return
      const streamId = serverStream.id

      setStreamsInfo((streamStatus) => ({
        ...streamStatus,
        [streamId]: {
          ...streamStatus[streamId],
          playbackStatus: {
            ...(streamStatus[streamId]?.playbackStatus || {}),
            ...serverStream,
            privileges: 1,
          },
        },
      }))
    })
  }, [damClient])

  return !!publisher && !!selectedStream?.id ? (
    <StreamPlayBarController
      key={selectedStream.id}
      publisher={publisher}
      selectedStream={selectedStream}
      streamInfo={streamsInfo[selectedStream.id] || {}}
      damClient={damClient}
      isStreamer={isStreamer}
      canPublish={canPublish}
    />
  ) : (
    <></>
  )
}

export default StreamPlayBar
