import React, { useMemo } from 'react'
import { Typography, TypographyProps } from '@material-ui/core'

/**
 * Format a time in milliseconds into a string of the form
 * HH:MM:SS;FF, where HH is hours, MM is minutes, SS is seconds,
 *
 * @param {number} [miliseconds=0] The time in milliseconds.
 * @returns {string} The formatted time string.
 */
const formatTime = (miliseconds: number = 0): string => {
  const seconds = miliseconds / 1000
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const secs = Math.floor(seconds % 60)

  const pad = (n: number): string => (n < 10 ? `0${n}` : n.toString())

  return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`
}

interface StreamTimecodeProps extends TypographyProps {
  streamProgress: number
  streamDuration: number
}

/**
 * A React component that displays a timecode for a given stream progress.
 *
 * The component takes in three props:
 * - `streamProgress`: the progress of the stream in milliseconds.
 * - `streamDuration`: the duration of the stream in milliseconds.
 *   the timecode. The default is 30.
 *
 * The component displays the timecode in the format HH:MM:SS;FF, where HH
 * is hours, MM is minutes, SS is seconds, and FF is frames.
 *
 */
const StreamTimecode: React.FC<StreamTimecodeProps> = ({
  streamProgress = 0,
  streamDuration = 0,
  ...restProps
}) => {
  const timeProcess = useMemo(() => formatTime(streamProgress), [streamProgress])

  const timeDuration = useMemo(() => formatTime(streamDuration), [streamDuration])

  return (
    <Typography variant="body2" {...restProps}>
      {timeProcess} / {timeDuration}
    </Typography>
  )
}

export default StreamTimecode
