/**
 * Maps a volume in the range [0, 1] to a decibel (db) value in the range [-60, 20].
 * The volume is assumed to be linear, and the db value is calculated using a logarithmic scale.
 * The edge case of volume being 0 is handled by returning -Infinity.
 *
 * @param {number} volume The volume value in range [0, 1].
 * @returns {number} The db value in range [-60, 20].
 */

export function volumeToDb(volume: number) {
  // To handle the edge case when volume is 0, which would give -Infinity in log scale.
  if (volume === 0) return -Infinity

  const MIN_GAIN_DB = -60
  const MAX_GAIN_DB = 20

  // Volume is in range [0, 1], but DB is in range [-60, 20].
  return MIN_GAIN_DB + volume * (MAX_GAIN_DB - MIN_GAIN_DB)
}
