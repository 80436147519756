import React from 'react'

export const videoWrapperClassName = 'video-stream-wrapper'
export const watermarkId = 'visible-watermark'
const styles: React.CSSProperties = {
  position: 'absolute',
  top: '0',
  left: '0',
  padding: '4.5rem',
  fontWeight: 700,
  fontSize: '1.8rem',
  color: '#ffffff',
  letterSpacing: 'normal',
  lineHeight: 'normal',
  textShadow:
    '1px 1px 0 black, 1px -1px 0 black, -1px -1px 0 black, -1px 1px 0 black, 2px 2px 5px rgb(0 0 0 / 25%)',
  display: 'none',
  pointerEvents: 'none',
  opacity: 0.6,
}

export function getWrapperElement(streamId) {
  if (streamId) {
    return document.querySelector(`.${videoWrapperClassName + '-' + CSS.escape(streamId)}`)
  }

  return document.querySelector(`.${videoWrapperClassName}`)
}

export function getVisibleWatermarkElementId(streamId) {
  const escapedStreamId = streamId.replace(/[^0-9a-z-]/gi, '')
  return `${watermarkId}-${escapedStreamId}`
}

export function getWatermarkElementId(streamId) {
  const escapedStreamId = streamId.replace(/[^0-9a-z-]/gi, '')
  return `${watermarkId}-${escapedStreamId}-img`
}

// we're doing it this way to make it less convenient to manually delete the watermark
export function turnOnWatermark(streamId, arr, dateString, base64Watermark) {
  try {
    const wrapperEl = getWrapperElement(streamId)
    if (!wrapperEl) throw new Error('No video wrapper element')
    const escapedStreamId = streamId.replace(/[^0-9a-z-]/gi, '')
    // find position to overlay video corner
    const videoEl = wrapperEl.querySelector('video')
    const vidWidth = videoEl?.videoWidth
    const vidHeight = videoEl?.videoHeight
    const vidRect = videoEl?.getBoundingClientRect()
    if (vidRect && vidWidth && vidHeight) {
      const heightRatio = vidRect.height / vidHeight
      const widthRatio = vidRect.width / vidWidth
      if (heightRatio < widthRatio) {
        const ratio = vidRect.height / vidHeight
        const actualWidth = ratio * vidWidth
        const leftPos = (vidRect.width - actualWidth) / 2
        styles.left = leftPos + 'px'
        styles.top = '0'
      } else {
        const ratio = vidRect.width / vidWidth
        const actualHeight = ratio * vidHeight
        const wrapperRect = wrapperEl.getBoundingClientRect() // the align-items center on the wrapper means we need to get this dimention when the heightRatio is greater than the widthRatio
        const topPos = (wrapperRect.height - actualHeight) / 2
        styles.top = topPos + 'px'
        styles.left = '0'
      }
    }
    styles.display = ''
    // create a new element
    const oldEl = wrapperEl.querySelector(`#${getVisibleWatermarkElementId(streamId)}`)
    const newWatermarkEl = document.createElement('div')
    newWatermarkEl.id = getVisibleWatermarkElementId(streamId)
    wrapperEl.append(newWatermarkEl)

    const info = ( arr && arr.length) ? arr.map((text) => `<div>${text}</div>`).join('') : ''
    const timeDiv = ( dateString ) ? `<div>${dateString}</div>` : ''
    newWatermarkEl.innerHTML = info + timeDiv
    for (const [prop, val] of Object.entries(styles)) {
      newWatermarkEl.style[prop] = val
    }
    oldEl?.remove()
    const oldImgEl = wrapperEl.querySelector(`#${getWatermarkElementId(streamId)}`)
    oldImgEl?.remove();

    if (base64Watermark) {
      addImageWatermark(wrapperEl, base64Watermark, escapedStreamId)
    }
  } catch (err) {
    console.error('Tampering with the watermark', err)
  }
}

export function turnOffWatermark(streamId) {
  try {
    const wrapperEl = getWrapperElement(streamId)
    if (!wrapperEl) throw new Error('No video wrapper element')

    const imageEl: HTMLElement | null = wrapperEl.querySelector(`#${getWatermarkElementId(streamId)}`)
    if (imageEl) {
      imageEl.style.display = 'none'
    }

    const watermarkEl = wrapperEl.querySelector(`#${getVisibleWatermarkElementId(streamId)}`)
    styles.display = 'none'
    for (const [prop, val] of Object.entries(styles)) {
      // @ts-ignore
      watermarkEl.style[prop] = val
    }
  } catch { }
}

function createOverlay(wrapperEl, imageElement: HTMLImageElement, streamId) {
  const videoEl = wrapperEl.querySelector('video')
  const vidRect = videoEl?.getBoundingClientRect()
  const wrapperRect = wrapperEl.getBoundingClientRect()

  const overlay = document.createElement('div');

  // If we have a streamId it means this is a thumbnail
  if (streamId) {
    overlay.style.left = '0'
    overlay.style.top = '0'
    overlay.style.width = '208px'
    overlay.style.height = '156px'
    imageElement.style.objectFit = 'fill'
  } else {
    const vidWidth = videoEl?.videoWidth || vidRect.width
    const vidHeight = videoEl?.videoHeight || vidRect.height
    const widthRatio = vidRect.width / vidWidth
    const heightRatio = vidRect.height / vidHeight
   
    const actualWidth = heightRatio * vidWidth
    let leftPos = Math.max((vidRect.width - actualWidth) / 2, 0)
    const actualHeight = widthRatio * vidHeight
    let topPos = Math.max((wrapperRect.height - actualHeight) / 2, 0)
    if (actualWidth > actualHeight) {
      overlay.style.height = vidRect.height + 'px';
      overlay.style.width = (vidRect.width - (2 * leftPos)) + 'px';
    } else {
      leftPos = Math.max((wrapperRect.width - actualWidth) / 2, 0)
      topPos = Math.max((vidRect.height - actualHeight) / 2, 0)
      overlay.style.width = actualWidth + 'px';
      overlay.style.height = (vidRect.height - (2 * topPos)) + 'px';
    }
    overlay.style.left = leftPos + 'px'
    overlay.style.top = topPos + 'px'
  }

  overlay.className = 'video-overlay';
  overlay.style.background = 'none';
  overlay.style.margin = '0';
  overlay.style.padding = '0';
  overlay.style.pointerEvents = 'none';

  overlay.style.position = 'absolute';
  overlay.id = getWatermarkElementId(streamId)
  overlay.appendChild(imageElement);
  wrapperEl.appendChild(overlay);
}

function createImg(imageData): HTMLImageElement {
  const imageElement = new Image();
  imageElement.style.width = '100%';
  imageElement.style.height = '100%';
  imageElement.src = imageData;
  imageElement.style.pointerEvents = 'none';
  return imageElement;
}

function addImageWatermark(wrapperEl, base64Watermark, streamId) {
  const imageElement = createImg(base64Watermark);
  createOverlay(wrapperEl, imageElement, streamId);
}