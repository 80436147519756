import { DamClient } from './../../../../../../../Common/dam/DamClient'
import { useCallback, useMemo } from 'react'
import { LiveroomParticipant } from './useLiveroomParticipants'
import { LiveroomPublisher } from './useLiveroomPublisher'
import { StreamInfo } from '../../StreamPlayBar/StreamPlayBar'
import { ParticipantType } from '../../../../../../../Common/janus/clients/liveroom/LiveroomClient'

export const DATA_CHANNEL_PLAYBACK_MESSAGE_TYPE = 'shared-file-playback'

export enum PlaybackAction {
  PLAY = 'play',
  PAUSE = 'pause',
  SEEK = 'seek',
  SET_VOLUME = 'setVolume',
}

interface PlaybackActionParams {
  milliseconds: number
  millis: number
  relative: boolean
}

interface VolumeActionParams {
  volume: number
}

export type PlaybackEvent =
  | {
      action: PlaybackAction.PLAY | PlaybackAction.PAUSE
      streamId: string
    }
  | {
      action: PlaybackAction.SEEK
      streamId: string
      actionParams: PlaybackActionParams
    }
  | {
      action: PlaybackAction.SET_VOLUME
      streamId: string
      actionParams: VolumeActionParams
    }

export interface PlaybackStatus {
  duration: number
  isPaused: boolean
  privileges: 0 | 1
  progress: number
  volume: number
}

export interface UseStreamPlaybackControlParams {
  selectedStream: LiveroomParticipant
  publisher: LiveroomPublisher
  streamInfo: Partial<StreamInfo>
  damClient: DamClient | null
}

/**
 * Returns a set of controls for a recorded stream.
 *
 * The component takes in two props:
 * - `publisher`: the publisher that can be used to control playback of the stream.
 * - `selectedStream`: the stream that should be played.
 * - `streamInfo`: the stream information which includes the playback status of the stream.
 *
 * The component returns an object with the following properties:
 * - `playStream`: a function that can be used to start playback of the stream.
 * - `pauseStream`: a function that can be used to pause playback of the stream.
 * - `setStreamVolume`: a function that can be used to set the volume of the stream.
 * - `seekForwardStream`: a function that can be used to seek forward in the stream.
 * - `seekBackwardStream`: a function that can be used to seek backward in the stream.
 * - `isPlaybackDisabled`: a boolean indicating whether playback of the stream is disabled.
 * - `isStreamPaused`: a boolean indicating whether playback of the stream is paused.
 * - `streamProgress`: the current progress of the stream (in milliseconds).
 * - `streamDuration`: the total duration of the stream (in milliseconds).
 * - `streamVolume`: the current volume of the stream (between 0 and 1).
 * - `streamId`: the ID of the stream.
 * - `streamDisplayName`: the display name of the stream.
 */
export const useStreamPlaybackControl = ({
  publisher,
  selectedStream,
  streamInfo,
  damClient,
}: UseStreamPlaybackControlParams) => {
  const { playbackStatus, frameRate } = streamInfo
  const { id } = selectedStream

  const isDamStream = selectedStream.type === ParticipantType.DAMStream

  const { privileges, isPaused, progress, duration, volume } = playbackStatus || {}
  const isPlaybackDisabled = useMemo(() => privileges === 0, [privileges])

  const playStream = useCallback(() => {
    const playbackData = { action: PlaybackAction.PLAY, streamId: id } as PlaybackEvent

    if (isDamStream) {
      damClient?.sendActionsMessage(playbackData)
    } else {
      publisher.playbackStream(playbackData)
    }
  }, [damClient, publisher, id, isDamStream])

  const pauseStream = useCallback(() => {
    const playbackData = { action: PlaybackAction.PAUSE, streamId: id } as PlaybackEvent

    if (isDamStream) {
      damClient?.sendActionsMessage(playbackData)
    } else {
      publisher.playbackStream(playbackData)
    }
  }, [publisher, id, isDamStream, damClient])

  const setStreamVolume = useCallback(
    (newVolume: number) => {
      const playbackData = {
        action: PlaybackAction.SET_VOLUME,
        streamId: id,
        actionParams: { volume: newVolume },
      } as PlaybackEvent

      if (isDamStream) {
        damClient?.sendActionsMessage(playbackData)
      } else {
        publisher.playbackStream(playbackData)
      }
    },
    [damClient, publisher, id, isDamStream]
  )

  const seekForwardStream = useCallback(() => {
    const playbackData = {
      action: PlaybackAction.SEEK,
      streamId: id,
      actionParams: { millis: 1, relative: true },
    } as PlaybackEvent

    if (isDamStream) {
      if (playbackData.action === PlaybackAction.SEEK && frameRate) {
        playbackData.actionParams.millis = playbackData.actionParams.millis * (1000 / frameRate)
      }

      damClient?.sendActionsMessage({ ...playbackData })
    } else {
      publisher.playbackStream(playbackData)
    }
  }, [damClient, publisher, id, isDamStream, frameRate])

  const seekBackwardStream = useCallback(() => {
    const playbackData = {
      action: PlaybackAction.SEEK,
      streamId: id,
      actionParams: { millis: -1, relative: true },
    } as PlaybackEvent

    if (isDamStream) {
      if (playbackData.action === PlaybackAction.SEEK && frameRate) {
        playbackData.actionParams.millis = playbackData.actionParams.millis * (1000 / frameRate)
      }

      damClient?.sendActionsMessage({ ...playbackData })
    } else {
      publisher.playbackStream(playbackData)
    }
  }, [damClient, publisher, id, isDamStream, frameRate])

  const seekStream = useCallback(
    (milliseconds: number) => {
      const playbackData = {
        action: PlaybackAction.SEEK,
        streamId: id,
        actionParams: { millis: milliseconds, relative: false },
      } as PlaybackEvent

      if (isDamStream) {
        damClient?.sendActionsMessage(playbackData)
      } else {
        publisher.playbackStream(playbackData)
      }
    },
    [damClient, publisher, id, isDamStream]
  )

  return {
    playStream,
    pauseStream,
    seekForwardStream,
    seekBackwardStream,
    setStreamVolume,
    seekStream,
    isPlaybackDisabled,
    isStreamPaused: isPaused ?? true,
    streamProgress: progress ?? 0,
    streamDuration: duration ?? 100,
    streamVolume: volume ?? 1,
  }
}
