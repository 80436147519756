import React, { useMemo, useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import AudioVolumeMeter from '../AudioVolumeMeter/AudioVolumeMeter'
import { AvailableStream, Stream, StreamState } from '../../../../../../../../Common/media/stream'
import { useIsMounted } from '../../../../../../../../Common/hooks/useIsMounted'
import { useEffectWithPredicate } from '../../../../../../../../Common/hooks/useEffectWithPredicate'
import { EvercastAudio } from '../../../../../../../../Common/audio/EvercastAudio'

const useStyles = makeStyles(() => ({
  markerContainer: {
    width: '78%',
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 15,
    position: 'relative',
    cursor: 'pointer',
  },
  marker: {
    width: 1,
    height: 15,
    background: 'rgba(249, 250, 251, 0.2)',
  },
  highlightedMarker: {
    width: 1.5,
    height: 15,
    background: 'rgba(249, 250, 251, 0.6)',
  },
  absoluteMarker: {
    width: 3,
    height: 17,
    borderRadius: 2,
    background: '#fff',
    position: 'absolute',
  },
  volumeMeter: {
    width: '100%',
    position: 'absolute',
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    gap: '6px',
  },
  disabled: {
    opacity: 0.75,
    cursor: 'default',
  },
}))

interface MarkerProps {
  streamVolume: number
  setStreamVolume: (value: number) => void
  stream: Stream
  disabled: boolean
}

/**
 * A component that renders a marker for the volume control slider.
 *
 * It takes the current volume and a function to update the volume as props.
 * It renders a line of 10 vertical bars, with the first `streamVolume * 10` bars
 * rendered with a highlighted class, and the remaining bars rendered with a normal class.
 * It also renders an absolute positioned vertical bar at the position of the volume,
 * which is used to handle user input.
 *
 * The user can click anywhere on the line to move the marker to the clicked position,
 * and can drag the marker to any position on the line.
 */
const Marker: React.FC<MarkerProps> = ({ streamVolume, setStreamVolume, stream, disabled }) => {
  const classes = useStyles()

  const newPosition = useMemo(() => streamVolume * 100, [streamVolume])
  const [leftVolume, setLeftVolume] = useState(0)
  const [rightVolume, setRightVolume] = useState(0)
  const isMounted = useIsMounted()

  // When the stream is available, we want to detect the volume of the stream using EvercastAudio,
  // and update the left and right volume state with the detected values.
  // We multiply the detected values by 100 to convert them to percentages.
  // We also use the isMounted ref to ensure that we don't update the state after the component has been unmounted.
  useEffectWithPredicate(
    {
      predicate: () => stream.state === StreamState.available,
      effect: () => {
        if ((stream as AvailableStream).current.getAudioTracks().length === 0) return

        const evercastAudio = new EvercastAudio()
        evercastAudio.detect(
          (stream as AvailableStream).current,
          null,
          (leftVolumeMark, rightVolumeMark) => {
            const leftVolumePercentage = Math.round((leftVolumeMark * 100) / streamVolume)
            const rightVolumePercentage = Math.round((rightVolumeMark * 100) / streamVolume)
            if (isMounted.current) {
              setLeftVolume(leftVolumePercentage)
              setRightVolume(rightVolumePercentage)
            }
          }
        )

        return () => {
          evercastAudio.cleanup()
        }
      },
    },
    [stream, isMounted, streamVolume]
  )

  // When the stream is not available, we want to reset the left and right volume state to 0.
  useEffectWithPredicate(
    {
      predicate: () =>
        stream.state !== StreamState.available && leftVolume !== 0 && rightVolume !== 0,
      effect: () => {
        setLeftVolume(0)
        setRightVolume(0)
      },
    },
    [stream, isMounted, leftVolume, rightVolume]
  )

  /**
   * Handles click event on the volume slider.
   *
   * Calculates the position of the click as a percentage of the slider width,
   * and updates the volume to that value.
   * @param e The React.MouseEvent object
   */
  const handleClick = (e: React.MouseEvent) => {
    if (disabled) return

    const sliderBox = e.currentTarget.getBoundingClientRect()
    const sliderWidth = sliderBox.width

    // Calculate the clicked position as a percentage of the slider width
    let newPosition = (e.clientX - sliderBox.left) / sliderWidth

    // Restrict to 0% and 100% boundaries
    if (newPosition < 0) newPosition = 0
    if (newPosition > 1) newPosition = 1

    const newVolume = newPosition
    setStreamVolume(newVolume)
  }

  /**
   * Handles mouse down event on the volume slider.
   *
   * Adds event listeners to window for mouse move and mouse up events.
   * When the mouse is moved, the marker is moved to the new position and the
   * volume is updated. When the mouse is released, the event listeners are removed.
   * @param e The React.MouseEvent object
   */
  const handleMouseDown = (e: React.MouseEvent) => {
    if (disabled) return

    const sliderBox = e.currentTarget.getBoundingClientRect()
    const sliderWidth = sliderBox.width

    const onMouseMove = (event: MouseEvent) => {
      // Calculate new marker position as a percentage
      let newPosition = (event.clientX - sliderBox.left) / sliderWidth

      // Restrict to 0% and 100% (left and right boundaries)
      if (newPosition < 0) newPosition = 0
      if (newPosition > 1) newPosition = 1

      const newVolume = newPosition
      setStreamVolume(newVolume)
    }

    const onMouseUp = () => {
      window.removeEventListener('mousemove', onMouseMove)
      window.removeEventListener('mouseup', onMouseUp)
    }

    window.addEventListener('mousemove', onMouseMove)
    window.addEventListener('mouseup', onMouseUp)
  }

  return (
    <Box
      className={classnames(classes.markerContainer, disabled && classes.disabled)}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
    >
      {Array.from({ length: 8 }, (_, index) => (
        <Box key={index} className={classes.marker} />
      ))}
      <Box className={classnames(classes.marker, classes.highlightedMarker)} />
      {Array.from({ length: 2 }, (_, index) => (
        <Box key={index} className={classes.marker} />
      ))}
      <Box
        className={classes.absoluteMarker}
        style={{
          left: `${newPosition}%`,
        }} // Set position dynamically
      />
      <Box className={classes.volumeMeter}>
        <AudioVolumeMeter volume={leftVolume} />
        <AudioVolumeMeter volume={rightVolume} />
      </Box>
    </Box>
  )
}

export default Marker
