import { LiveroomParticipant, useLiveroomParticipants } from './useLiveroomParticipants'
import { MediaSelection } from '../../hooks/useMediaSetup'
import { useLiveroomPublisher } from './useLiveroomPublisher'
import { RecordingState, useLiveroomRecording } from './useLiveroomRecording'
import { useLiveroomClient } from './useLiveroomClient'
import { useEffect, useMemo } from 'react'
import { useFullScreen } from '../../../../../../../Common/hooks/useFullScreen'
import { useLiveroomDialogs } from './useLiveroomDialogs'
import { LocalDevicesActions, LocalDevicesState } from '../../hooks/useLocalDevicesState'
import { useLiveroomDiagnosticsReporting } from './useLiveroomDiagnosticsReporting'
import {
  Participant,
  ParticipantType,
  Publisher,
} from '../../../../../../../Common/janus/clients/liveroom/LiveroomClient'
import { useLiveroomStreamSelection } from './useLiveroomStreamSelection'
import { Stream } from '../../../../../../../Common/media/stream'
import { useJanusSignallingConnection } from '../../../../../../../Common/janus/signalling/hooks/useJanusSignallingConnection'
import { useScreenshareClient } from './useScreenshareClient'
import { VideoQuality } from '../../../../../../../Common/video/videoQualityOptions'
import { useBrowserStreamClient } from './useBrowserStreamClient'
import { BrowserStreamMediaConstraints } from '../../../BrowserStream/hooks/useBrowserStreamMediaConstraints'
import { ProfilePreferences } from '../../../../../../../Common/preferences/usePreferences'
import { useThumbnailsControl } from './useThumbnailsControl'
import { useActiveSpeakerDetection } from './useActiveSpeakerDetection'
import { useLiveroomDecisions } from './useLiveroomPermissions'
import { useRecordingNotificationDialog } from './useRecordingNotificationDialog'
import { MediaDevicesInfo } from '../../hooks/useMediaDevices'
import { useClientsideDiagnostics } from './useClientsideDiagnostics'
import { useAdaptiveStreaming } from './useAdaptiveStreaming'
import { useDoNotDisturb } from '../../../../../../../Components/DoNotDisturb/hooks/useDoNotDisturb'
import { useDamClient } from '../../../../../../../Common/dam/useDamClient'
import { useModerationWebSocket } from '../../../../../../../Common/hooks/useModerationWebSocket'
import { useWebSocketServerUrl } from '../../../../../../../Common/hooks/useWebSocketServerUrl'
import { RoomAccess } from '../../../../../../../Models/apiEntities'

export interface StreamSelectionControl {
  active: Stream
  selectedId: string
  selectPublisher: () => void
  selectAndLockParticipant: (participant: LiveroomParticipant) => void
  unselectAndUnlockParticipant: () => void
  type?: ParticipantType
}
export interface DrawCanvasControl {
  activeStreamId: string
  publisher: Publisher
  participants: Participant[]
}
export interface CameraControl {
  isEnabled: boolean
  setIsEnabled: (boolean) => void
  noDevices: boolean
  cameraSetToNone: boolean
  isAvailable: boolean
}
export interface MicrophoneControl {
  isEnabled: boolean
  setIsEnabled: (boolean) => void
  noDevices: boolean
  microphoneSetToNone: boolean
  isAvailable: boolean
}
export interface AudioControl {
  isEnabled: boolean
  setIsEnabled: (boolean) => void
}
export interface FullScreenModeControl {
  isEnabled: boolean
  isAvailable: boolean
  setIsEnabled: (boolean) => void
}
export interface DeviceSelectionControl {
  isAvailable: boolean
  openDialog: () => void
}
export interface DialogControl {
  isOpen: boolean
  open: () => void
  close: () => void
}
export interface RoomAccessControl {
  isAvailable: boolean
  dialog: DialogControl
}
export interface RoomSettingsControl {
  isAvailable: boolean
  dialog: DialogControl
}
export interface EbsKeysControl {
  isAvailable: boolean
  dialog: DialogControl
}
export interface ScreenShareControl {
  isAvailable: boolean
  isActive: boolean
  startScreenShare: (videoQuality: VideoQuality) => void
  stopScreenShare: () => void
  dialog: DialogControl
}
export interface BrowserStreamControl {
  isAvailable: boolean
  isActive: boolean
  startBrowserStream: (browserStreamConstraints: BrowserStreamMediaConstraints) => void
  stopBrowserStream: () => void
  dialog: DialogControl
}
export interface RecordingControl {
  isAvailable: boolean
  isRecording: boolean
  isEnabled: boolean
  canWatchList: boolean
  dialog: DialogControl
  handleClick: () => void
  startRecording: (recordingName: string) => void
}
export interface InstantHelpControl {
  isAvailable: boolean
}
export interface ThumbnailsControl {
  isAvailable: boolean
  areVisible: boolean
  toggleVisibility: () => void
}

export interface AppleTVLoginControl {
  isAvailable: boolean
  dialog: DialogControl
}

export interface AudioMixerControl {
  isAvailable: boolean
  dialog: DialogControl
}

export interface UserMutedControl {
  dialog: DialogControl
}

export interface DnDAccessControl {
  isAvailable: boolean
  isAllowed: boolean
  isActive: boolean
  isLoading: boolean
  onConfirm: (roomId: string, roomLock: boolean) => Promise<void>
  dialog: DialogControl
  dndParticipants?: {
    profileId?: string
    email?: string
    authenticationMethod?: 'sso' | 'guest' | 'okta'
  }[]
  approveAccess?: (profileId: string, roomId: string) => void
  denyAccess?: (profileId: string, roomId: string) => void
}

export interface RecordingNotificationControl {
  dialog: {
    isOpen: boolean
    open: () => void
    close: () => void
    isHidden: boolean
    toggleHide: () => void
  }
}

interface useLiveroomParams {
  room
  roomInfo: RoomAccess
  currentProfile
  mediaSelection: MediaSelection
  localDevicesState: LocalDevicesState
  localDevicesActions: LocalDevicesActions
  openDeviceSelectionDialog: () => void
  iceServers
  profilePreferences: ProfilePreferences
  profileApiBasedPermissions: string[]
  mediaDevices: MediaDevicesInfo
}

export const useLiveroom = ({
  room,
  roomInfo,
  currentProfile,
  mediaSelection,
  localDevicesState,
  localDevicesActions,
  openDeviceSelectionDialog,
  iceServers,
  profilePreferences,
  profileApiBasedPermissions,
  mediaDevices,
}: useLiveroomParams) => {
  const webSocketServerUrl = useWebSocketServerUrl()
  const serverUrl = useMemo(
    () => (webSocketServerUrl ? `${webSocketServerUrl}?roomId=${room.id}` : null),
    [webSocketServerUrl, room]
  )

  function isLiveRoomEvent(message) {
    const parsedMessage = JSON.parse(message.data)
    return parsedMessage.type === 'dnd'
  }

  const { sendJsonMessage, lastJsonMessage, readyState } = useModerationWebSocket(
    room.id,
    isLiveRoomEvent
  )

  // Run when the Moderation connection state (readyState) changes
  useEffect(() => {
    console.log(`Connection state changed for Moderation ws, new state is ${readyState}`)
  }, [readyState])

  /**
   * Manages signalling layer of WebRTC connection.
   * Establishes the websocket connection and retries the connection in case of interruption.
   */
  const { janusSignallingConnection, signallingConnectionState } = useJanusSignallingConnection({
    iceServers,
    serverUrl,
  })
  const { state, stateDescription, liveroomClient } = useLiveroomClient({
    room,
    roomInfo,
    currentProfile,
    janusSignallingConnection,
    signallingConnectionState,
  })
  const { damClient } = useDamClient({ state })
  const { isScreenShareStarted, startScreenShare, stopScreenShare } = useScreenshareClient({
    signallingConnectionState,
    janusSignallingConnection,
    room,
    currentProfile,
  })
  const { isBrowserStreamStarted, startBrowserStream, stopBrowserStream } = useBrowserStreamClient({
    signallingConnectionState,
    janusSignallingConnection,
    room,
    currentProfile,
  })

  const {
    recordPermissionInfo,
    chatPermissionInfo,
    notePermissionInfo,
    screensharePermissionInfo,
    browserStreamPermissionInfo,
    roomEditionPermissionInfo,
    roomInvitePermissionInfo,
    appleTVPermissionInfo,
    audioMixerPermissionInfo,
    ebsKeysPermissionInfo,
    kickPermissionInfo,
    mutePermissionInfo,
    dndPermissionInfo,
    canPublishInfo,
  } = useLiveroomDecisions({ room, roomInfo, currentProfile, profileApiBasedPermissions })

  const { publisher, publisherModel } = useLiveroomPublisher({
    liveroomClient,
    mediaSelection,
    localDevicesState,
    localDevicesActions,
    room,
    canPublish: canPublishInfo.isAllowed,
  })
  const {
    participants,
    participantsModel,
    lastJoinedParticipantModel,
    participantsManager,
  } = useLiveroomParticipants({
    liveroomClient,
    damClient,
    mediaSelection,
    localDevicesState,
    currentProfile,
  })

  const activeSpeakerDetectionControl = useActiveSpeakerDetection({ participants })
  const streamSelection: StreamSelectionControl = useLiveroomStreamSelection({
    participants: participantsModel,
    publisher: publisherModel,
    lastJoinedParticipant: lastJoinedParticipantModel,
    activeSpeakerDetectionControl,
    canPublish: canPublishInfo.isAllowed,
  })

  useAdaptiveStreaming({
    isEnabled: true,
    publisher,
    participants: participants,
    activeParticipantId: streamSelection.selectedId,
  })

  /**
   * Subscribes to recording events and exposes method to start/stop recording
   */
  const {
    state: recordingState,
    recordingInfo,
    startRecording,
    stopRecording,
  } = useLiveroomRecording({
    publisher,
    room,
    currentProfile,
    isRecordingAllowed: recordPermissionInfo.isAllowed,
  })

  /**
   * Manages updates to the Do not Disturb state of a room
   */
  const {
    roomLock,
    isInProgress: isLoadingDnD,
    onConfirmDnd,
    onDndUpdated,
    approveAccess,
    denyAccess,
    addParticipant,
    removeParticipant,
    participants: dndParticipants,
  } = useDoNotDisturb(room, sendJsonMessage)

  /**
   * Manages thumbnail state and syncs it with user preferences
   */
  const thumbnailsControl = useThumbnailsControl(profilePreferences)

  /**
   * Manages full screen mode
   */
  const { isFullScreenMode, toggleFullScreenMode } = useFullScreen()

  /**
   * Manages all the liveroom dialog states
   */
  const {
    recordingDialog,
    roomSettingsDialog,
    roomAccessDialog,
    screenShareDialog,
    ebsKeysDialog,
    browserStreamDialog,
    appleTVLoginDialog,
    audioMixerDialog,
    userMutedDialog,
    dndDialog,
  } = useLiveroomDialogs()

  const { recordingNotificationDialog } = useRecordingNotificationDialog({
    recordingInfo,
    currentProfile,
  })

  const { setCSD, isCSDEnabled, csdResults } = useClientsideDiagnostics({
    publisher: publisher!,
    room,
  })

  const drawCanvas: DrawCanvasControl = useMemo(
    () => ({
      activeStreamId: streamSelection.selectedId,
      publisher: publisher!,
      participants,
    }),
    [streamSelection.selectedId, publisher, participants]
  )

  const camera: CameraControl = useMemo(
    () => ({
      isEnabled: localDevicesState.isCameraEnabled,
      setIsEnabled: localDevicesActions.setIsCameraEnabled,
      noDevices: !mediaDevices.camera.devices.length,
      cameraSetToNone: !mediaSelection?.camera,
      isAvailable: canPublishInfo.isAllowed,
    }),
    [localDevicesState, localDevicesActions, mediaDevices.camera.devices.length, mediaSelection]
  )

  const microphone: MicrophoneControl = useMemo(
    () => ({
      isEnabled: localDevicesState.isMicrophoneEnabled,
      setIsEnabled: localDevicesActions.setIsMicrophoneEnabled,
      noDevices: !mediaDevices.microphone.devices.length,
      PPTShortcut: mediaSelection.PPTShortcut,
      microphoneSetToNone: !mediaSelection?.microphone,
      isAvailable: canPublishInfo.isAllowed,
    }),
    [localDevicesState, localDevicesActions, mediaDevices.microphone.devices.length, mediaSelection]
  )

  const audio: AudioControl = useMemo(
    () => ({
      isEnabled: localDevicesState.isAudioOutputEnabled,
      setIsEnabled: localDevicesActions.setIsAudioOutputEnabled,
    }),
    [localDevicesState, localDevicesActions]
  )

  const fullScreenMode: FullScreenModeControl = useMemo(
    () => ({
      isAvailable: true,
      isEnabled: isFullScreenMode,
      setIsEnabled: toggleFullScreenMode,
    }),
    [isFullScreenMode, toggleFullScreenMode]
  )

  const deviceSelection: DeviceSelectionControl = useMemo(
    () => ({
      isAvailable: true,
      openDialog: openDeviceSelectionDialog,
    }),
    [openDeviceSelectionDialog]
  )

  const recording: RecordingControl = useMemo(
    () => ({
      isAvailable: recordPermissionInfo.isAvailable,
      isEnabled: recordPermissionInfo.isAllowed && recordingState !== RecordingState.progress,
      isRecording: recordingInfo.isRecording,
      canWatchList: recordPermissionInfo.canWatchList,
      handleClick: () => {
        if (!recordPermissionInfo.isAllowed) return
        if (recordingState === RecordingState.awaiting) recordingDialog.open()
        else stopRecording()
      },
      dialog: recordingDialog,
      startRecording,
    }),
    [
      recordingDialog,
      recordingState,
      startRecording,
      stopRecording,
      recordPermissionInfo,
      recordingInfo.isRecording,
    ]
  )

  const roomAccess: RoomAccessControl = useMemo(
    () => ({
      isAvailable: roomInvitePermissionInfo.isAvailable && roomInvitePermissionInfo.isAllowed,
      dialog: roomAccessDialog,
    }),
    [roomAccessDialog, roomInvitePermissionInfo]
  )

  const screenShare: ScreenShareControl = useMemo(
    () => ({
      isAvailable: screensharePermissionInfo.isAvailable && screensharePermissionInfo.isAllowed,
      isActive: isScreenShareStarted,
      startScreenShare,
      stopScreenShare,
      dialog: screenShareDialog,
    }),
    [
      screenShareDialog,
      isScreenShareStarted,
      startScreenShare,
      stopScreenShare,
      screensharePermissionInfo,
    ]
  )

  const browserStream: BrowserStreamControl = useMemo(
    () => ({
      isAvailable: browserStreamPermissionInfo.isAvailable && browserStreamPermissionInfo.isAllowed,
      isActive: isBrowserStreamStarted,
      startBrowserStream,
      stopBrowserStream,
      dialog: browserStreamDialog,
    }),
    [
      browserStreamDialog,
      isBrowserStreamStarted,
      startBrowserStream,
      stopBrowserStream,
      browserStreamPermissionInfo,
    ]
  )

  const ebsKeys: EbsKeysControl = useMemo(
    () => ({
      isAvailable:
        ebsKeysPermissionInfo.isAvailable &&
        ebsKeysPermissionInfo.isAllowed &&
        canPublishInfo.isAllowed,
      dialog: ebsKeysDialog,
    }),
    [ebsKeysDialog, ebsKeysPermissionInfo.isAvailable, ebsKeysPermissionInfo.isAllowed]
  )

  const roomSettings: RoomSettingsControl = useMemo(
    () => ({
      isAvailable: roomEditionPermissionInfo.isAvailable && roomEditionPermissionInfo.isAllowed,
      dialog: roomSettingsDialog,
    }),
    [roomSettingsDialog, roomEditionPermissionInfo]
  )

  const audioMixer: AudioMixerControl = useMemo(
    () => ({
      isAvailable: audioMixerPermissionInfo.isAvailable && audioMixerPermissionInfo.isAllowed,
      dialog: audioMixerDialog,
    }),
    [audioMixerDialog, audioMixerPermissionInfo.isAvailable, audioMixerPermissionInfo.isAllowed]
  )

  const instantHelp: InstantHelpControl = useMemo(
    () => ({
      isAvailable: true,
    }),
    []
  )

  const appleTVLogin: AppleTVLoginControl = useMemo(
    () => ({
      isAvailable: appleTVPermissionInfo.isAvailable && appleTVPermissionInfo.isAllowed,
      dialog: appleTVLoginDialog,
    }),
    [appleTVLoginDialog, appleTVPermissionInfo.isAvailable, appleTVPermissionInfo.isAllowed]
  )

  const recordingNotification: RecordingNotificationControl = useMemo(
    () => ({
      dialog: recordingNotificationDialog,
    }),
    [recordingNotificationDialog]
  )

  const userMuted: UserMutedControl = useMemo(
    () => ({
      dialog: userMutedDialog,
    }),
    [userMutedDialog]
  )

  const dndControl: DnDAccessControl = useMemo(
    () => ({
      isAvailable: dndPermissionInfo.isAvailable,
      isAllowed: dndPermissionInfo.isAllowed,
      dialog: dndDialog,
      isActive: roomLock,
      isLoading: isLoadingDnD,
      onConfirm: onConfirmDnd,
      dndParticipants,
      approveAccess,
      denyAccess,
    }),
    [
      dndDialog,
      dndPermissionInfo.isAvailable,
      dndPermissionInfo.isAllowed,
      roomLock,
      isLoadingDnD,
      onConfirmDnd,
      dndParticipants,
      approveAccess,
      denyAccess,
    ]
  )

  /**
   * Manages the information to send off to Diagnostics Reporting
   */
  useLiveroomDiagnosticsReporting({
    room,
    currentProfile,
    localDevicesState,
    mediaSelection,
    signallingConnectionState,
    liveroomClient,
    microphone,
    isFullScreenMode,
  })

  // Run when a new Moderation WebSocket message is received (lastJsonMessage)
  useEffect(() => {
    if (lastJsonMessage)
      switch (lastJsonMessage?.type) {
        case 'dnd':
          switch (lastJsonMessage?.action) {
            case 'startLockdown':
              onDndUpdated(true)
              break
            case 'stopLockdown':
              onDndUpdated(false)
              break
            case 'participantRequestedAccess':
              addParticipant(lastJsonMessage?.data)
              break
            case 'participantApproved':
            case 'participantDenied':
            case 'participantLeft':
              removeParticipant(lastJsonMessage?.data.profileId)
              break
            default:
              console.log(
                `Unrecognized messaged of type dnd received through the Moderation ws connection, message: ${lastJsonMessage}`
              )
              break
          }
          break
        default:
          console.log(
            `Unrecognized messaged received through the Moderation ws connection, message: ${lastJsonMessage}`
          )
          break
      }
  }, [lastJsonMessage, onDndUpdated, addParticipant, removeParticipant])

  return {
    state,
    stateDescription,
    streamSelection,
    recordingInfo,
    drawCanvas,
    publisher: publisherModel,
    _publisher: publisher,
    participants: participantsModel,
    participantsManager,
    chatPermissionInfo,
    notePermissionInfo,
    camera,
    microphone,
    audio,
    fullScreenMode,
    deviceSelection,
    recording,
    roomAccess,
    screenShare,
    browserStream,
    ebsKeys,
    roomSettings,
    instantHelp,
    thumbnails: thumbnailsControl,
    appleTVLogin,
    audioMixer,
    recordingNotification,
    userMuted,
    setCSD,
    isCSDEnabled,
    csdResults,
    kickPermissionInfo,
    mutePermissionInfo,
    dndControl,
    canPublishInfo,
    liveroomClient,
    damClient,
  }
}
