import React, { useMemo } from 'react'
import { Box, Grid, IconButton, makeStyles, Slider, styled, Typography } from '@material-ui/core'
import EjectIcon from '@material-ui/icons/Eject'
import PauseIcon from '@material-ui/icons/Pause'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import {
  useStreamPlaybackControl,
  UseStreamPlaybackControlParams,
} from '../../LiveRoom/hooks/useStreamPlaybackControl'
import StreamTimecode from './../StreamTimecode/StreamTimecode'
import StreamVolumeControl from './../StreamVolumeControl/StreamVolumeControl'

interface StreamPlaybackControllerProps extends UseStreamPlaybackControlParams {
  isStreamer: boolean
  canPublish: boolean
}

const useStyles = makeStyles((theme) => ({
  controlBar: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    padding: '4px 64px',
  },
  iconButton: {
    color: 'inherit',
    padding: 0,
  },
  text: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  noPadding: {
    padding: 0,
  },
  backwardIcon: {
    transform: 'rotate(-90deg)',
  },
  forwardIcon: { transform: 'rotate(90deg)' },
}))

const WhiteSlider = styled(Slider)({
  color: '#FFFFFF',
  padding: 0,
  '& .MuiSlider-thumb': {
    color: '#F6F6F6',
    marginTop: '-2px',
    width: '8px',
    height: '8px',
  },
  '& .MuiSlider-track': {
    height: '4px',
  },
  '& .MuiSlider-rail': {
    backgroundColor: '#767777',
    height: '4px',
  },
})

/**
 * A React component that displays a set of controls for a recorded stream.
 *
 * The component displays a seek-backward button, a play/pause button, a seek-forward button,
 * a volume control, and a timecode display.
 *
 * The component takes in these props:
 * - `publisher`: the publisher that can be used to control playback of the stream.
 * - `selectedStream`: the stream that should be played.
 * - `streamInfo`: the stream information which includes the playback status of the stream.
 * - `isStreamer`: a boolean indicating whether the user is the streamer.
 * - `damClient`: the DAM client that can be used to control playback of the stream.
 *
 * The component uses the `useStreamPlaybackControl` hook to get the stream's current state
 * and a set of functions that can be used to control playback of the stream.
 */
const StreamPlayBarController: React.FC<StreamPlaybackControllerProps> = ({
  publisher,
  selectedStream,
  streamInfo,
  damClient,
  isStreamer,
  canPublish,
}) => {
  const {
    isPlaybackDisabled,
    isStreamPaused,
    streamProgress,
    streamDuration,
    streamVolume,
    setStreamVolume,
    playStream,
    pauseStream,
    seekStream,
    seekBackwardStream,
    seekForwardStream,
  } = useStreamPlaybackControl({ publisher, selectedStream, streamInfo, damClient })

  const isDisabled = !canPublish || isPlaybackDisabled

  const { displayName, stream } = selectedStream
  const { playbackStatus } = streamInfo

  const classes = useStyles()
  const canControl = useMemo(() => !!playbackStatus, [playbackStatus])

  if (!canControl)
    return (
      <Box className={classes.controlBar}>
        <Typography variant="body2">{displayName}</Typography>
      </Box>
    )

  return (
    <Grid container className={classes.controlBar} alignItems="center">
      <Grid item xs={12} className={classes.noPadding}>
        <WhiteSlider
          value={streamProgress}
          min={0}
          max={streamDuration}
          step={1}
          disabled={isDisabled}
          onChange={(e, newValue) => seekStream(newValue as number)}
        />
      </Grid>
      <Grid item xs={12} className={classes.noPadding}>
        <Grid container justify="space-between" alignItems="center" className={classes.noPadding}>
          <Grid item className={classes.noPadding}>
            <Grid
              container
              justify="flex-start"
              alignItems="center"
              className={classes.noPadding}
              spacing={3}
            >
              <Grid item className={classes.noPadding}>
                <Typography variant="body2">{displayName}</Typography>
              </Grid>
              <Grid item className={classes.noPadding}>
                {isStreamer && (
                  <StreamVolumeControl
                    streamVolume={streamVolume}
                    setStreamVolume={setStreamVolume}
                    stream={stream}
                    disabled={isDisabled}
                  />
                )}
              </Grid>
              <Grid item className={classes.noPadding}>
                <IconButton
                  className={classes.iconButton}
                  onClick={seekBackwardStream}
                  disabled={isDisabled}
                >
                  <EjectIcon className={classes.backwardIcon} />
                </IconButton>
              </Grid>
              <Grid item className={classes.noPadding}>
                <IconButton
                  className={classes.iconButton}
                  disabled={isDisabled}
                  onClick={isStreamPaused ? playStream : pauseStream}
                >
                  {isStreamPaused ? <PlayArrowIcon /> : <PauseIcon />}
                </IconButton>
              </Grid>
              <Grid item className={classes.noPadding}>
                <IconButton
                  className={classes.iconButton}
                  onClick={seekForwardStream}
                  disabled={isDisabled}
                >
                  <EjectIcon className={classes.forwardIcon} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} className={classes.noPadding}>
            <Box display="flex" justifyContent="flex-end">
              <StreamTimecode
                streamProgress={streamProgress}
                streamDuration={streamDuration}
                className={classes.text}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default StreamPlayBarController
