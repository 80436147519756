import { ParticipantType } from '../janus/clients/liveroom/LiveroomClient'

const nonStreamParticipantsList = [
  ParticipantType.Local,
  ParticipantType.Remote,
  ParticipantType.AppleTV,
  ParticipantType.Dummy,
]

/**
 * Returns true if the given participant type is a stream type.
 *
 * @param {ParticipantType} type - Participant type to check
 * @returns {boolean} true if the given type is a stream type
 */
export const isStreamParticipant = (type: ParticipantType | undefined | null): boolean => {
  return !!type && !nonStreamParticipantsList.includes(type)
}
