import { Box, BoxProps, makeStyles } from '@material-ui/core'
import React, { useMemo } from 'react'
import classnames from 'classnames'

interface AudioVolumeMeterProps extends BoxProps {
  volume: number
}

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    width: '100%',
  },
  bar: {
    height: 3,
    position: 'absolute',
    left: 0,
  },
  greenBar: {
    backgroundColor: '#10B981',
  },
  yellowBar: {
    backgroundColor: '#FBBF24',
  },
  redBar: {
    backgroundColor: '#DC2626',
  },
}))

/**
 * A component that displays a volume Meter.
 *
 * The component consists of 3 bars, green, yellow and red.
 * The green bar represents the volume from 0 to 50.
 * The yellow bar represents the volume from 50 to 80.
 * The red bar represents the volume from 80 to 100.
 *
 * The width of each bar is calculated based on the given volume.
 *
 * @param {number} volume The current volume of the stream.
 * @param {string} className The class name of the component.
 * @param {BoxProps} props The props of the Box component.
 * @returns {React.ReactElement} The volume Meter component.
 */
const AudioVolumeMeter: React.FC<AudioVolumeMeterProps> = ({ volume, className, ...props }) => {
  const classes = useStyles()
  const greenBar = useMemo(() => (volume >= 50 ? 50 : volume), [volume])
  const yellowBar = useMemo(() => (volume >= 80 ? 80 : volume), [volume])
  const redBar = useMemo(() => volume, [volume])

  return (
    <Box className={classnames(classes.container, className)} {...props}>
      <Box className={classnames(classes.bar, classes.redBar)} style={{ width: `${redBar}%` }} />
      <Box
        className={classnames(classes.bar, classes.yellowBar)}
        style={{
          width: `${yellowBar}%`,
        }}
      />
      <Box
        className={classnames(classes.bar, classes.greenBar)}
        style={{
          width: `${greenBar}%`,
        }}
      />
    </Box>
  )
}

export default AudioVolumeMeter
