import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Tooltip, Typography, makeStyles } from '@material-ui/core'
import { VolumeOff, VolumeUp } from '@material-ui/icons'
import classnames from 'classnames'
import { Stream } from '../../../../../../../Common/media/stream'
import IconButton from '../../../../../../../Components/Buttons/IconButton/IconButton'
import Marker from './Marker/Marker'
import { volumeToDb } from './utils/utils'

const useStyles = makeStyles(() => ({
  volumeContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  volumeSlider: {
    width: 207,
    height: 21,
    flexShrink: 0,
    border: '0.5px solid rgba(249, 250, 251, 0.2)',
    background: 'rgba(15, 15, 15, 0.4)',
    borderRadius: 2,
    padding: '3px 9px 3px 6px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 15,
  },
  dbLabel: {
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: 9,
    fontWeight: 500,
    lineHeight: '13px',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  iconButton: {
    color: 'inherit',
    padding: 0,
  },
  noPadding: {
    padding: 0,
  },
  pointer: {
    cursor: 'pointer',
  },
  disabled: {
    opacity: 0.75,
    cursor: 'default',
  },
}))

const DEFAULT_VOLUME = 0.75

interface StreamVolumeControlProps {
  stream: Stream
  streamVolume: number
  setStreamVolume: (value: number) => void
  disabled: boolean
}

/**
 * A component that displays a volume slider for a stream.
 * The slider is used to control the volume of the stream.
 * The component also displays a button to toggle the mute state of the stream.
 * The button is a VolumeOff icon when the stream is muted, and a VolumeUp icon when it is not muted.
 * When the stream volume changes, the component is updated to reflect the new volume.
 * When the mute state changes, the component is updated to reflect the new mute state.
 * The component also displays the db value of the volume in a small text label.
 * The db value is calculated using the volumeToDb function, which maps the volume from [0, 1]
 * to [-60, 20] using a logarithmic scale.
 * The component is designed to be used in a StreamPlayBar component.
 */
const StreamVolumeControl: React.FC<StreamVolumeControlProps> = ({
  streamVolume,
  setStreamVolume,
  stream,
  disabled,
}) => {
  const classes = useStyles()
  const [volume, setVolume] = useState<number>(streamVolume || DEFAULT_VOLUME)

  const DBText = useMemo(() => {
    const DBValue = volumeToDb(streamVolume)

    if (DBValue === -Infinity || isNaN(DBValue)) return '-INF'
    if (DBValue > 0) return '+' + DBValue.toFixed(1)

    return DBValue.toFixed(1)
  }, [streamVolume])

  const handleToggleMute = useCallback(() => {
    setStreamVolume(streamVolume === 0 ? volume : 0)
  }, [streamVolume, setStreamVolume, volume])

  const handleResetVolume = useCallback(() => {
    setStreamVolume(DEFAULT_VOLUME)
  }, [setStreamVolume])

  // Update the volume when the stream volume changes
  useEffect(() => {
    if (streamVolume && streamVolume !== volume) {
      setVolume(streamVolume)
    }
  }, [streamVolume, volume])

  return (
    <Box className={classes.volumeContainer}>
      <IconButton
        className={classes.iconButton}
        onClick={handleToggleMute}
        icon={streamVolume === 0 ? VolumeOff : VolumeUp}
        disabled={disabled}
      />
      <Box className={classes.volumeSlider}>
        <Marker
          stream={stream}
          streamVolume={streamVolume}
          setStreamVolume={setStreamVolume}
          disabled={disabled}
        />
        <Box
          width={32}
          display="flex"
          justifyContent="flex-end"
          className={classes.pointer}
          onClick={handleResetVolume}
        >
          <Tooltip className={classes.noPadding} title="reset" placement="top">
            <Typography className={classnames(classes.dbLabel, disabled && classes.disabled)}>
              {DBText}DB
            </Typography>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  )
}

export default StreamVolumeControl
