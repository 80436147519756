import React, { memo, MutableRefObject, useCallback, useState } from 'react'
import classnames from 'classnames'
import { Box } from '@material-ui/core'
import { ErrorOutline } from '@material-ui/icons'
import './Thumbnail.scss'
import { WithCondition } from '../WithCondition/WithCondition'
import { CSDErrorCodes, useCSDContext } from '../../Providers/CSDProvider'
import { ReactComponent as MuteIcon } from '../../Static/images/muteIndicator.svg'
import { ReactComponent as LockIcon } from '../../Static/images/lock.svg'
import { videoWrapperClassName } from '../Watermark/toggleWatermark'

interface Props {
  name: string
  id: string
  isParticipantSpeaking?: boolean
  isConnectionLost?: boolean
  isNameAlwaysVisible?: boolean
  actions?: React.ReactNode
  children?: React.ReactNode
  onClick?: () => void
  canvas?: React.ReactNode
  networkConnectivityIndicator?: JSX.Element
  shouldShowMuteIndicator?: boolean
  shouldShowConnectivityStats?: boolean
  thumbnailConnectivityStats?: JSX.Element
  thumbnailRef?: MutableRefObject<any>
  showForensicWatermarkError?: boolean;
}

const Thumbnail: React.FC<Props> = ({
  name,
  id,
  isParticipantSpeaking,
  isNameAlwaysVisible,
  isConnectionLost,
  actions,
  children,
  onClick,
  canvas,
  networkConnectivityIndicator,
  shouldShowMuteIndicator,
  shouldShowConnectivityStats,
  thumbnailConnectivityStats,
  thumbnailRef,
  showForensicWatermarkError = false,
}) => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false)
  const onMouseOverHandler = useCallback(() => setIsOverlayVisible(true), [])
  const onMouseOutHandler = useCallback(() => setIsOverlayVisible(false), [])
  const { sendEntryToCSD } = useCSDContext()

  if (isConnectionLost) sendEntryToCSD(CSDErrorCodes.grayThumbnail)
  return (
    <Box
      className={classnames('thumbnail', {
        'thumbnail-participant-speaking': isParticipantSpeaking,
      }, videoWrapperClassName + '-' + id)} // this className is used to align the watermark
      onMouseOver={onMouseOverHandler}
      onMouseOut={onMouseOutHandler}
      onClick={onClick}
      data-testid="thumbnail"
    >
      <h2
        className={classnames('thumbnail-participant-name', {
          'thumbnail-participant-name-visible': isNameAlwaysVisible || isOverlayVisible,
        })}
        ref={thumbnailRef}
      >
        {name}
      </h2>

      <WithCondition condition={!!children}>
        <Box className="thumbnail-content">{children}</Box>
      </WithCondition>

      <WithCondition condition={!!networkConnectivityIndicator}>
        <Box className="thumbnail-network-connectivity-indicator">
          {networkConnectivityIndicator}
        </Box>
      </WithCondition>

      <WithCondition condition={!!shouldShowMuteIndicator}>
        <Box className="thumbnail-mute">
          <MuteIcon />
        </Box>
      </WithCondition>

      <WithCondition condition={showForensicWatermarkError}>
        <Box className="thumbnail-lock">
          <LockIcon />
        </Box>
      </WithCondition>

      <WithCondition condition={!isConnectionLost}>
        <Box
          className={classnames('thumbnail-overlay', {
            'thumbnail-overlay-visible': isOverlayVisible,
            'thumbnail-overlay-no-bg': !children,
          })}
        >
          <WithCondition condition={!!actions}>
            <Box className="thumbnail-overlay-action-buttons">{actions}</Box>
          </WithCondition>
        </Box>
      </WithCondition>

      <WithCondition condition={!!canvas}>
        <Box className="thumbnail-canvas" data-testid="thumbnail-canvas">
          {canvas}
        </Box>
      </WithCondition>

      <WithCondition condition={!!isConnectionLost}>
        <Box
          className="thumbnail-overlay-connection-lost"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <ErrorOutline fontSize="inherit" className="thumbnail-overlay-connection-lost-icon" />
          <Box>Lost Connection</Box>
        </Box>
      </WithCondition>

      <WithCondition condition={!!shouldShowConnectivityStats && !!thumbnailConnectivityStats}>
        {thumbnailConnectivityStats}
      </WithCondition>
    </Box>
  )
}

export default memo(Thumbnail)
